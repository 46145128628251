import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // action timeline
    refreshPortfolioForClientAccounts(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`refreshPortfolioForClientAccounts`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    refreshPortfolioForClientAccountsVerified(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`refreshPortfolioForClientAccountsVerified`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    refreshPortfolioForClientAccountsNotVerified(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`refreshPortfolioForClientAccountsNotVerified`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    insertIntoCustomer(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`insertIntoCustomer`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    selectFromCustomer(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectFromCustomer`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //view account
    selectAccNoFromCustomer(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectAccNoFromCustomer`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    accountDetailsExist(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`accountDetailsExist`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    accountNumberExist(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`accountNumberExist`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkAccountDetailsExist(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkAccountDetailsExist`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateAmendAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateAmendAccount`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkVerifyAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkVerifyAccount`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    verifyAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`verifyAccount`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAllAccountCodes(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAllAccountCodes`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getSelectedAccountCodes(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSelectedAccountCodes`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createCodeSelectedAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createCodeSelectedAccount`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    insertIntoCodeAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`insertIntoCodeAccount`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //trade Multiple Change Account Status Opr
    tradeMultipleChangeAccountStatusOpr(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeMultipleChangeAccountStatusOpr`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getTaxRates(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getTaxRates`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getClients(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getClients`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getLastAccountNumbers(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastAccountNumbers`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateAccountStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateAccountStatus`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    bringArchivedAccounts(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`bringArchivedAccounts`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
