<template>
  <div>
    <!-- first card -->
    <b-card class="mb-1">
      <template #header>
        <b-row
          class=" flex-row justify-content-between align-items-center"
          style="width: 100%"
        >
          <b-col cols="12" xl="6" lg="6" md="6" sm="6">
            <h4 class="mb-0">
              {{ $t("account_creation.titles.account_creation") }}
            </h4>
          </b-col>

          <b-col cols="12" xl="2" lg="2" md="6" sm="6">
            <b-table-simple
              hover
              small
              caption-top
              responsive
              bordered
              class="mb-0"
            >
              <b-tbody style="font-size:0.6rem;gap:5px;">
                <b-tr>
                  <td style="width: 30%;" class="white-space">
                    {{ $t("account_creation.titles.amended") }}
                  </td>
                  <td style="background-color: #ffff99;width: 30%;"></td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </template>

      <hr />
      <b-row class="justify-content-start align-items-center">
        <b-col
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          style="display:flex;justify-content:center;align-items:center"
          class="list-pages-buttons"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="flat-info"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="getAllEntries"
          >
            <!-- <feather-icon
                icon="BoldIcon"
                size="14"
                style="margin-bottom: 0.3rem;"
              /> -->
            <b-icon
              icon="people-fill"
              font-scale="1"
              style="margin-bottom: 0.3rem;"
            />
            <span class="text-nowrap">{{
              $t("account_creation.buttons.bring_all")
            }}</span>
          </b-button>
        </b-col>
        <b-col
          v-if="$Can('search_account')"
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          style="display:flex;justify-content:center;align-items:center"
          class="list-pages-buttons"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="flat-dark"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="searchAccountModal"
          >
            <b-icon
              icon="person-lines-fill"
              font-scale="1"
              style="margin-bottom: 0.3rem;"
            >
            </b-icon>
            <span class="text-nowrap">{{
              $t("account_creation.buttons.search_accounts")
            }}</span>
          </b-button>
        </b-col>
        <b-col
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          style="display:flex;justify-content:center;align-items:center"
          class="list-pages-buttons"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="flat-dark"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="verifiedEntries"
          >
            <b-icon
              icon="patch-check"
              font-scale="1"
              style="margin-bottom: 0.3rem;"
            >
            </b-icon>
            <span class="text-nowrap">{{
              $t("account_creation.buttons.bring_verified_entries")
            }}</span>
          </b-button>
        </b-col>
        <b-col
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          style="display:flex;justify-content:center;align-items:center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="flat-secondary"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="unverifiedEntries"
          >
            <b-icon
              icon="patch-question"
              font-scale="1"
              style="margin-bottom: 0.3rem;"
            >
            </b-icon>
            <span class="text-nowrap">{{
              $t("account_creation.buttons.bring_unverified_entries")
            }}</span>
          </b-button>
        </b-col>
        <b-col
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          style="display:flex;justify-content:center;align-items:center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="flat-secondary"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="bringAllAccountCodes()"
          >
            <feather-icon
              icon="EyeIcon"
              size="14"
              style="margin-bottom: 0.3rem;"
            />
            <span class="text-nowrap">{{
              $t("account_creation.buttons.bring_all_account_currencies")
            }}</span>
          </b-button>
        </b-col>

        <b-col
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          style="display:flex;justify-content:center;align-items:center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="flat-secondary"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="bringArchivedAccounts()"
          >
            <feather-icon
              icon="EyeIcon"
              size="14"
              style="margin-bottom: 0.3rem;"
            />
            <span class="text-nowrap">{{
              $t("account_creation.buttons.bring_archived_accounts")
            }}</span>
          </b-button>
        </b-col>

        <b-col
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          style="display:flex;justify-content:center;align-items:center"
          class="list-pages-buttons"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="gradient-success"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="createAccountModal"
          >
            <feather-icon
              icon="PlusIcon"
              size="14"
              style="margin-bottom: 0.3rem;"
            />
            <span class="text-nowrap">{{
              $t("account_creation.buttons.new_account_entry")
            }}</span>
          </b-button>
        </b-col>
        <!-- <b-col
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          style="display:flex;justify-content:center;align-items:center;"
          class="list-pages-buttons"
        >
          <b-button
            id="info-legend-button"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            size="sm"
            class="compact_nav_icon-text-button"
          >
            <feather-icon
              icon="EyeIcon"
              size="14"
              style="margin-bottom: 0.3rem;"
            />

            <span class="">{{
              $t("account_creation.buttons.legend_table")
            }}</span>
          </b-button>
          <b-popover
            target="info-legend-button"
            placement="right"
            triggers="focus"
            id="actions-popover-legend"
            class="popover-body"
          >
            <template #title>
              {{ $t("account_creation.titles.legend") }}
            </template>
            <b-table-simple hover small caption-top responsive bordered>
              <b-tbody>
                <b-tr>
                  <td>
                    {{ $t("account_creation.titles.amended") }}
                  </td>
                  <td style="background-color: #ffff99;width: 60%;"></td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-popover>
        </b-col> -->
      </b-row>
    </b-card>

    <!-- second card -->
    <b-card>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <!-- list accounts table -->
        <div
          @contextmenu.prevent="$refs.contextMenu.open"
          v-if="allAccounts.length > 0"
        >
          <div>
            <b-table
              hover
              :bordered="true"
              :sticky-header="true"
              ref="refAccountCreationTable"
              class="compact_table"
              :items="filteredTrades"
              :filter="filter"
              filter-debounce="250"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
              thead-class="animate__animated animate__fadeIn"
              :no-border-collapse="true"
              :small="true"
              select-mode="single"
              head-variant="dark"
              :fields="tableColumns"
              show-empty
              :empty-text="
                $t('account_creation.messages.no_matching_account_found')
              "
              :current-page="currentPage"
              :per-page="perPage"
              :tbody-tr-class="legendClass"
              caption-top
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortByDesc"
            >
              <!-- @row-clicked="onRowSelected" -->

              <!-- Column: column filter -->
              <template
                slot="top-row"
                slot-scope="{ fields }"
                v-if="showColumnsFilter"
              >
                <td
                  :class="field.key == 'id' ? 'sticky-column' : ''"
                  v-for="field in tableColumns"
                  :key="field.key"
                >
                  <v-select
                    v-if="field.key == 'HolderType'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="
                      field.key + ' column_search_input compact_form_vue-select'
                    "
                    :options="optHolderTypes"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />
                  <v-select
                    v-else-if="field.key == 'SettlementType'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="
                      field.key + ' column_search_input compact_form_vue-select'
                    "
                    :options="optSettlementTypes"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />
                  <v-select
                    v-else-if="field.key == 'status'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="
                      field.key + ' column_search_input compact_form_vue-select'
                    "
                    :options="optStatuses"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />

                  <input
                    v-else-if="field.key == 'ID'"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="'ID_column_search_input compact_form-input'"
                    style="max-width: 75px !important;"
                  />

                  <input
                    v-else
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="
                      field.key + ' column_search_input compact_form-input'
                    "
                  />
                  <!-- <b-form-input v-model="filters[field.key]" :placeholder="field.label" :class="field.key" /> -->
                </td>
              </template>

              <!-- Column: check box -->
              <!-- trade controls -->
              <template #cell(id)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <b
                    ><span class="font-weight-bold">
                      <strong class="text-primary">#{{ data.item.id }}</strong>
                    </span></b
                  >
                </div>
                <div
                  v-if="!showMultiplecheckboks && filteredTrades.length > 0"
                  class="d-flex justify-content-center align-items-center compact_controls_button"
                >
                  <b-button
                    :id="
                      'trade-controls-' + data.item
                        ? data.item.id.toString()
                        : ''
                    "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="top-btns animate__animated animate__fadeIn"
                    @click="
                      showTradeControlsPopover(
                        'trade-controls-' + data.item
                          ? data.item.id.toString()
                          : '',
                        data.item
                      )
                    "
                    v-if="$Can('see_controls_menu')"
                  >
                    <i class="fa-solid fa-bars"></i>
                  </b-button>
                  <b-popover
                    :target="
                      'trade-controls-' + data.item
                        ? data.item.id.toString()
                        : ''
                    "
                    triggers="click blur"
                    variant="primary"
                  >
                    <template #title>
                      {{ $t("equity_bond.titles.trade_controls") }}
                    </template>

                    <b-button
                      v-if="$Can('verify_account') && seeVerifyButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-success"
                      size="sm"
                      class="top-btns mt-1"
                      @click="verifySelectedAccount(data.item)"
                    >
                      <span class="trades-font"
                        ><feather-icon
                          icon="CheckSquareIcon"
                          style="margin-right: 0.3rem;"
                        />{{
                          $t("account_creation.buttons.verify_selected_account")
                        }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('amend_account')"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-warning"
                      size="sm"
                      class="top-btns mt-1"
                      @click="amendEntry(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="EditIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("account_creation.buttons.amend_entry") }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('view_account')"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="viewAccountModal(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="EyeIcon"
                          style="margin-right: 0.3rem;"
                        />{{
                          $t("account_creation.buttons.view_account")
                        }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('create_code') && seeCreateNewAccountButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="createCodeSelectedAccount(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="ClipboardIcon"
                          style="margin-right: 0.3rem;"
                        />{{
                          $t(
                            "account_creation.buttons.create_new_account_currency"
                          )
                        }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('view_codes') && seeBringAccountButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="bringCodesSelectedAccount(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="ListIcon"
                          style="margin-right: 0.3rem;"
                        />{{
                          $t(
                            "account_creation.buttons.bring_account_currencies"
                          )
                        }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="
                        $Can('view_codes') && data.item.Status != 'Archived'
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="updateAccountStatus(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="ListIcon"
                          style="margin-right: 0.3rem;"
                        />{{
                          $t("account_creation.buttons.archive_account")
                        }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('view_codes') && data.item.Status != 'Active'"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="updateAccountStatus(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="ListIcon"
                          style="margin-right: 0.3rem;"
                        />{{
                          $t("account_creation.buttons.activate_account")
                        }}</span
                      >
                    </b-button>
                  </b-popover>
                </div>
                <div
                  v-if="showMultiplecheckboks"
                  class="d-flex justify-content-center align-items-center compact_controls_button"
                >
                  <b-form-checkbox
                    v-model="data.item.selectedTrade"
                    @change="multiSelectRow(data.item.selectedTrade, data.item)"
                    plain
                    class="animate__animated animate__fadeIn"
                  >
                  </b-form-checkbox>
                </div>
              </template>

              <!-- Column: Status -->
              <template #cell(status)="data">
                <b-badge
                  v-if="data.value != true"
                  pill
                  :variant="`light-success`"
                  class="text-capitalize"
                  style="background-color:rgba(255, 255, 255, 0.5)"
                >
                  {{ $t("account_creation.text.verified") }}
                </b-badge>

                <b-badge
                  v-else
                  pill
                  :variant="`light-danger`"
                  class="text-capitalize"
                  style="background-color:rgba(255, 255, 255, 0.5)"
                >
                  {{ $t("account_creation.text.not_verified") }}
                </b-badge>
              </template>

              <!-- Column: username -->
              <template #cell(username)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.username ? data.item.username : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <template #cell(CreationDate)="data">
                <span>{{ dateFormat(data.value) }}</span>
              </template>

              <template #cell(VerificationDate)="data">
                <span>{{ dateFormat(data.value) }}</span>
              </template>

              <template #cell(CreationTime)="data">
                <span>{{ data.value }}</span>
              </template>

              <template #cell(VerificationTime)="data">
                <span>{{ data.value }}</span>
              </template>

              <template #cell(VerifiedBy)="data">
                <span>{{ data.value ? data.value : "-" }}</span>
              </template>

              <template #cell(ClientCommPerContract)="data">
                <span>{{ data.value ? formatPrice(data.value) : null }}</span>
              </template>
            </b-table>
          </div>
        </div>
        <!-- mouse right click -->
        <vue-context ref="contextMenu">
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showColumnsFilter')"
            >
              <feather-icon
                :icon="!showColumnsFilter ? 'EyeIcon' : 'EyeOffIcon'"
                size="16"
              />
              <span class="ml-75">{{
                !showColumnsFilter
                  ? "Show column filters"
                  : "Hide column filters"
              }}</span>
            </b-link>
          </li>
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showMultiplecheckboks')"
            >
              <feather-icon
                :icon="
                  !showMultiplecheckboks ? 'CheckSquareIcon' : 'EyeOffIcon'
                "
                size="16"
              />
              <span class="ml-75">{{
                !showMultiplecheckboks
                  ? "Show multiple checkbox"
                  : "Hide multiple checkbox"
              }}</span>
            </b-link>
          </li>
          <li v-if="selectedMultipleTrades.length > 0">
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc('copyTrades')"
            >
              <feather-icon icon="CopyIcon" size="16" />
              <span class="ml-75">{{
                $t("fx_spot_forward_swap.buttons.copy_trade")
              }}</span>
            </b-link>
          </li>

          <li v-if="selectedMultipleTrades.length > 0 && !onlyArchived">
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc('archiveTrades')"
            >
              <feather-icon icon="ListIcon" size="16" />
              <span class="ml-75">{{
                $t("account_creation.buttons.archive_account")
              }}</span>
            </b-link>
          </li>

          <li
            v-if="multiSelectListStatusData.length > 0"
            v-for="(optStatus, index) in multiSelectListStatusData"
            :key="index"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc(optStatus.key)"
            >
              <feather-icon
                :icon="optStatus.icon"
                size="16"
                :stroke="optStatus.color"
              />
              <span class="ml-75">{{ optStatus.title }}</span>
            </b-link>
          </li>
        </vue-context>

        <div class="mx-2 mb-2" v-if="allAccounts.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    {{/** view modal failed multi select process*/}}
    <b-modal
      id="multiSelectProcessModal"
      v-model="showMultiSelectProcessModal"
      :title="$t('multiCheckBoxTitles.multiple_process_title')"
      :ok-title="$t('general_buttons.close')"
      @ok="okMultiSelectProcessModal"
      @hidden="okMultiSelectProcessModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="primary"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refMultiSelectProcessModal"
                class="position-relative"
                :items="multiSelectProcessModalTableData"
                selectable
                select-mode="single"
                responsive
                head-variant="light"
                :fields="multiSelectProcessModalTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(ID)="data">
                  <strong>{{ data.item.ID }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>
                <template #cell(info)="data">
                  <b-badge
                    v-if="data.item.info == 'Pass'"
                    pill
                    :variant="`light-success`"
                    class="text-capitalize"
                    style="background-color:rgba(255, 255, 255, 0.5)"
                  >
                    {{ data.item.info }}
                  </b-badge>

                  <b-badge
                    v-else
                    pill
                    :variant="`light-danger`"
                    class="text-capitalize"
                    style="background-color:rgba(255, 255, 255, 0.5)"
                  >
                    {{ data.item.info }}
                  </b-badge>
                </template>

                <template #cell(reason)="data">
                  <strong>{{ data.item.reason }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view modal failed multi select process*/}}
    </b-modal>

    {{ /*account create modal*/ }}
    <b-modal
      id="accountModal"
      v-model="accountCreateModal"
      :title="$t('account_creation.titles.account_management_screen')"
      @hidden="resetAccountModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('account_creation.modal.create_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="createAccount"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
    >
      <b-overlay :show="AccountModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="createValidation">
            <b-form>
              <b-row class="mt-1">
                <b-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <b-card>
                    <b-row>
                      <!-- Account Name-->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.account_name')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.account_name')
                            "
                            rules="required"
                          >
                            <b-form-input
                              id="AccountName"
                              size="sm"
                              type="text"
                              :state="errors.length > 0 ? false : null"
                              @blur="accountNameNumberCheck('create')"
                              v-model="accountData.AccountName"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Account Number-->
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.account_number')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.account_number')
                            "
                            rules="required|min:10|max:10"
                          >
                            <b-form-input
                              id="AccountNumber"
                              @blur="accountNumberCheck('create')"
                              size="sm"
                              type="number"
                              v-model="accountData.AccountNumber"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Holder Type-->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.holder_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.holder_type')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="accountData.HolderType"
                              :label="
                                $t('account_creation.account_form.holder_type')
                              "
                              id="HolderType"
                              :clearable="false"
                              :options="holderTypes"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Setlement Type-->
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.settlement_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'account_creation.account_form.settlement_type'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="accountData.SettlementType"
                              :label="
                                $t(
                                  'account_creation.account_form.settlement_type'
                                )
                              "
                              id="SettlementType"
                              :clearable="false"
                              :options="settlementTypes"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Tax Residency-->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.tax_residency')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="accountData.TaxResidency"
                              :label="
                                $t(
                                  'account_creation.account_form.tax_residency'
                                )
                              "
                              id="TaxResidency"
                              :clearable="false"
                              :options="countryNames"
                              @option:selected="selectRate"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <!-- Holder Type-->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group
                          :label="$t('account_creation.account_form.client_id')"
                        >
                          <v-select
                            v-model="accountData.ClientId"
                            label="fullname"
                            :reduce="(val) => val.id"
                            id="clientId"
                            :clearable="false"
                            :options="clientList"
                            class="compact_form_vue-multi-select"
                            multiple
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- UNCOMMENT WITH FX OPTION ACTIVATION -->
                    <!-- Client Comm Per Contract -->

                    <!-- <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('account_creation.account_form.client_comm_pcontract')"
                          
                        >
                        <cleave
                            id="ClientCommPerContract"
                            ref="ClientCommPerContract"
                            v-model="accountData.ClientCommPerContract"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('account_creation.account_form.client_comm_pcontract')
                            "
                          />
                        </b-form-group>
                      </b-col>
                    </b-row> -->
                  </b-card>
                </b-col>

                <b-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                  <b-card>
                    <b-row>
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <!-- Display Name-->
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.display_name')
                          "
                        >
                          <b-form-input
                            id="DisplayName"
                            size="sm"
                            type="text"
                            v-model="accountData.DisplayName"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Phone Number-->
                        <b-form-group
                          :label="$t('account_creation.account_form.phone')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('account_creation.account_form.phone')"
                            rules="min:10|max:12"
                          >
                            <b-form-input
                              id="Phone1"
                              size="sm"
                              type="number"
                              v-model="accountData.Phone1"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Phone Number 2 -->
                        <b-form-group
                          :label="$t('account_creation.account_form.phone2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('account_creation.account_form.phone2')"
                            rules="min:10|max:12"
                          >
                            <b-form-input
                              id="Phone2"
                              size="sm"
                              type="number"
                              v-model="accountData.Phone2"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Contact Email-->

                        <b-form-group
                          :label="
                            $t('account_creation.account_form.contact_email')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.contact_email')
                            "
                            rules="email"
                          >
                            <b-form-input
                              id="ContactEmail"
                              size="sm"
                              :state="errors.length > 0 ? false : null"
                              type="email"
                              v-model="accountData.ContactEmail"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Email 2-->

                        <b-form-group
                          :label="$t('account_creation.account_form.email2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('account_creation.account_form.email2')"
                            rules="email"
                          >
                            <b-form-input
                              id="Email2"
                              size="sm"
                              :state="errors.length > 0 ? false : null"
                              type="email"
                              v-model="accountData.Email2"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <!-- Address-->
                        <b-form-group
                          :label="$t('account_creation.account_form.adress')"
                        >
                          <b-form-textarea
                            class="compact-textarea-lg"
                            no-resize
                            rows="2"
                            size="sm"
                            id="Address"
                            type="textarea"
                            v-model="accountData.Address"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <!-- Client Notes-->
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.client_notes')
                          "
                        >
                          <b-form-textarea
                            class="compact-textarea-lg"
                            no-resize
                            rows="2"
                            size="sm"
                            id="ClientNote"
                            type="textarea"
                            v-model="accountData.ClientNote"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>

        <template #overlay v-if="AccountModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("account_creation.overlay_messages.account_creating_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <!-- trade ok operation buttons -->

          <b-col
            cols="12"
            md="12"
            xl="12"
            lg="12"
            sm="12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1 modal-btn"
              @click="resetAccountModal"
            >
              {{ $t("account_creation.modal.modal_cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1 modal-btn"
              @click="createAccount"
            >
              {{ $t("account_creation.modal.create_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    {{/****END*** account create modal*/ }}

    {{ /*account search modal*/ }}
    <b-modal
      id="accountModal"
      v-model="accountSearchModal"
      :title="$t('account_creation.titles.search_account')"
      @hidden="resetAccountModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('account_creation.modal.search_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="searchAccount"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
    >
      <b-overlay :show="AccountModalLoading" rounded="sm" no-fade>
        <b-container>
          <b-form>
            <b-row class="mt-1">
              <b-col
                cols="12"
                xl="6"
                lg="6"
                md="12"
                sm="12"
                class="pl-0"
                style="padding-right:0.5rem!important"
              >
                <b-card>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Account Name-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.account_name')
                        "
                      >
                        <b-form-input
                          id="AccountName"
                          size="sm"
                          type="text"
                          v-model="accountData.AccountName"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Account Number-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.account_number')
                        "
                      >
                        <b-form-input
                          id="AccountNumber"
                          size="sm"
                          type="number"
                          v-model="accountData.AccountNumber"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Holder Type-->
                      <b-form-group
                        :label="$t('account_creation.account_form.holder_type')"
                      >
                        <v-select
                          v-model="accountData.HolderType"
                          :label="
                            $t('account_creation.account_form.holder_type')
                          "
                          id="HolderType"
                          clearable
                          :options="holderTypes"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Setlement Type-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.settlement_type')
                        "
                      >
                        <v-select
                          v-model="accountData.SettlementType"
                          :label="
                            $t('account_creation.account_form.settlement_type')
                          "
                          id="SettlementType"
                          :clearable="true"
                          :options="settlementTypes"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- tax residency countries -->

                      <b-form-group
                        :label="
                          $t('account_creation.account_form.tax_residency')
                        "
                      >
                        <v-select
                          v-model="accountData.TaxResidency"
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                          id="TaxResidency"
                          :clearable="false"
                          :options="countryNames"
                          @option:selected="selectRate"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- UNCOMMENT WITH FX OPTION ACTIVATION -->

                  <!-- Client Comm Per Contract -->

                  <!-- <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('account_creation.account_form.client_comm_pcontract')"
                          
                        >
                        <cleave
                            id="ClientCommPerContract"
                            ref="ClientCommPerContract"
                            v-model="accountData.ClientCommPerContract"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('account_creation.account_form.client_comm_pcontract')
                            "
                          />
                        </b-form-group>
                      </b-col>
                    </b-row> -->

                  <b-row>
                    <!-- Holder Type-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('account_creation.account_form.client_id')"
                      >
                        <v-select
                          v-model="accountData.ClientId"
                          label="fullname"
                          :reduce="(val) => val.id"
                          id="clientId"
                          :clearable="false"
                          :options="clientList"
                          class="compact_form_vue-multi-select"
                          multiple
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>

              <b-col
                cols="12"
                xl="6"
                lg="6"
                md="12"
                sm="12"
                class="pr-0"
                style="padding-left:0.5rem!important"
              >
                <b-card>
                  <!-- Display Name-->

                  <b-row>
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.display_name')
                        "
                      >
                        <b-form-input
                          id="DisplayName"
                          size="sm"
                          type="text"
                          v-model="accountData.DisplayName"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Phone Number-->
                      <b-form-group
                        :label="$t('account_creation.account_form.phone')"
                      >
                        <b-form-input
                          id="Phone1"
                          size="sm"
                          type="tel"
                          v-model="accountData.Phone1"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Phone Number 2 -->
                      <b-form-group
                        :label="$t('account_creation.account_form.phone2')"
                      >
                        <b-form-input
                          id="Phone2"
                          size="sm"
                          type="tel"
                          v-model="accountData.Phone2"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Contact Email-->

                      <b-form-group
                        :label="
                          $t('account_creation.account_form.contact_email')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('account_creation.account_form.contact_email')
                          "
                          rules="email"
                        >
                          <b-form-input
                            id="ContactEmail"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            v-model="accountData.ContactEmail"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Email 2-->

                      <b-form-group
                        :label="$t('account_creation.account_form.email2')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('account_creation.account_form.email2')"
                          rules="email"
                        >
                          <b-form-input
                            id="Email2"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            v-model="accountData.Email2"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Address-->
                      <b-form-group
                        :label="$t('account_creation.account_form.adress')"
                      >
                        <b-form-textarea
                          class="compact-textarea-lg"
                          rows="3"
                          size="sm"
                          id="Address"
                          type="textarea"
                          v-model="accountData.Address"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Client Notes-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.client_notes')
                        "
                      >
                        <b-form-textarea
                          class="compact-textarea-lg"
                          rows="3"
                          size="sm"
                          id="ClientNote"
                          type="textarea"
                          v-model="accountData.ClientNote"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </b-container>

        <template #overlay v-if="AccountModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />
              {{
                $t("account_creation.overlay_messages.account_checking_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>

      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <!-- trade ok operation buttons -->

          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class="d-flex justify-content-start align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="
                accountData.History == null || accountData.History.length == 0
              "
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">Audit Log</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="focus"
              id="actions-popover"
              class="popover-body"
            >
              <template #title>
                Audit Log
              </template>
              <div class="scrollable-popover">
                <b-table-simple hover small caption-top responsive bordered>
                  <b-tbody class="compact_form_actions-table">
                    <b-tr
                      v-for="(action, index) in accountData.History"
                      :key="index"
                    >
                      <td style="width: 20%;">
                        {{ action.type }}
                      </td>
                      <td style="width: 20%;">
                        {{ action.user }}
                      </td>
                      <td style="width: 30%;">
                        {{ action.date }}
                      </td>
                      <td style="width: 30%;">
                        {{ action.reason }}
                      </td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-popover>
          </b-col>

          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1 modal-btn"
              @click="resetAccountModal"
            >
              {{ $t("account_creation.modal.modal_cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1 modal-btn"
              @click="searchAccount"
            >
              {{ $t("account_creation.modal.search_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    {{/****END*** account search modal*/ }}

    {{ /*account view modal*/ }}
    <b-modal
      id="accountModal"
      v-model="accountViewModal"
      :title="$t('account_creation.titles.account_viewing_form')"
      @hidden="resetAccountModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('account_creation.modal.view_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="viewAccount"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
    >
      <b-overlay :show="AccountModalLoading" rounded="sm" no-fade>
        <b-container>
          <b-form>
            <b-row class="mt-1">
              <b-col
                cols="12"
                xl="6"
                lg="6"
                md="12"
                sm="12"
                class="pl-0"
                style="padding-right:0.5rem!important"
              >
                <b-card>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Account Name-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.account_name')
                        "
                      >
                        <b-form-input
                          id="AccountName"
                          ref="AccountName"
                          size="sm"
                          type="text"
                          v-model="accountData.AccountName"
                          :disabled="true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Account Number-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.account_number')
                        "
                      >
                        <b-form-input
                          id="AccountNumber"
                          size="sm"
                          type="number"
                          v-model="accountData.AccountNumber"
                          :disabled="true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Holder Type-->
                      <b-form-group
                        :label="$t('account_creation.account_form.holder_type')"
                      >
                        <v-select
                          v-model="accountData.HolderType"
                          :label="
                            $t('account_creation.account_form.holder_type')
                          "
                          id="HolderType"
                          ref="HolderType"
                          :clearable="false"
                          :options="holderTypes"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Setlement Type-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.settlement_type')
                        "
                      >
                        <v-select
                          v-model="accountData.SettlementType"
                          :label="
                            $t('account_creation.account_form.settlement_type')
                          "
                          id="SettlementType"
                          ref="SettlementType"
                          :clearable="false"
                          :options="settlementTypes"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.tax_residency')
                        "
                      >
                        <v-select
                          v-model="accountData.TaxResidency"
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                          id="TaxResidency"
                          ref="TaxResidency"
                          :clearable="false"
                          :options="countryNames"
                          :disabled="true"
                          @option:selected="selectRate"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- UNCOMMENT WITH FX OPTION ACTIVATION -->

                  <!-- Client Comm Per Contract -->

                  <!-- <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('account_creation.account_form.client_comm_pcontract')"
                          
                        >
                        <cleave
                            id="ClientCommPerContract"
                            ref="ClientCommPerContract"
                            v-model="accountData.ClientCommPerContract"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('account_creation.account_form.client_comm_pcontract')
                            "
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row> -->

                  <b-row>
                    <!-- Holder Type-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('account_creation.account_form.client_id')"
                      >
                        <ul class="full-names-list">
                          <li
                            v-for="clientId in accountData.ClientId"
                            :key="clientId"
                          >
                            {{ getClientName(clientId) }}
                          </li>
                          <li
                            v-if="
                              accountData.ClientId == [] ||
                                accountData.ClientId == null
                            "
                          >
                            No Clients
                          </li>
                        </ul>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>

              <b-col
                cols="12"
                xl="6"
                lg="6"
                md="12"
                sm="12"
                class="pr-0"
                style="padding-left:0.5rem!important"
              >
                <b-card>
                  <!-- Display Name-->

                  <b-row>
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.display_name')
                        "
                      >
                        <b-form-input
                          id="DisplayName"
                          ref="DisplayName"
                          size="sm"
                          type="text"
                          v-model="accountData.DisplayName"
                          :disabled="true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Phone Number-->
                      <b-form-group
                        :label="$t('account_creation.account_form.phone')"
                      >
                        <b-form-input
                          id="Phone1"
                          ref="Phone1"
                          size="sm"
                          type="tel"
                          v-model="accountData.Phone1"
                          :disabled="true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Phone Number 2 -->
                      <b-form-group
                        :label="$t('account_creation.account_form.phone2')"
                      >
                        <b-form-input
                          id="Phone2"
                          ref="Phone2"
                          size="sm"
                          type="tel"
                          v-model="accountData.Phone2"
                          :disabled="true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Contact Email-->

                      <b-form-group
                        :label="
                          $t('account_creation.account_form.contact_email')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('account_creation.account_form.contact_email')
                          "
                          rules="email"
                        >
                          <b-form-input
                            id="ContactEmail"
                            ref="ContactEmail"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            v-model="accountData.ContactEmail"
                            :disabled="true"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Email 2-->

                      <b-form-group
                        :label="$t('account_creation.account_form.email2')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('account_creation.account_form.email2')"
                          rules="email"
                        >
                          <b-form-input
                            id="Email2"
                            ref="Email2"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            v-model="accountData.Email2"
                            :disabled="true"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Address-->
                      <b-form-group
                        :label="$t('account_creation.account_form.adress')"
                      >
                        <b-form-textarea
                          class="compact-textarea-lg"
                          ref="Address"
                          rows="3"
                          size="sm"
                          id="Address"
                          type="textarea"
                          v-model="accountData.Address"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Client Notes-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.client_notes')
                        "
                      >
                        <b-form-textarea
                          class="compact-textarea-lg"
                          ref="ClientNote"
                          rows="3"
                          size="sm"
                          id="ClientNote"
                          type="textarea"
                          v-model="accountData.ClientNote"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </b-container>

        <template #overlay v-if="AccountModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("account_creation.overlay_messages.account_vieving_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <!-- trade ok operation buttons -->

          <b-col
            cols="8"
            md="8"
            xl="8"
            lg="8"
            sm="12"
            class="pl-0 d-flex justify-content-start align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="
                accountData.History == null || accountData.History.length == 0
              "
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">Audit Log</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="focus"
              id="actions-popover"
            >
              <template #title>
                Audit Log
              </template>
              <div>
                <b-table-simple hover small caption-top responsive bordered>
                  <b-tbody class="compact_form_actions-table">
                    <b-tr
                      v-for="(action, index) in accountData.History"
                      :key="index"
                    >
                      <td style="width: 20%;">
                        {{ action.type }}
                      </td>
                      <td style="width: 20%;">
                        {{ action.user }}
                      </td>
                      <td style="width: 30%;">
                        {{ action.date }}
                      </td>
                      <td style="width: 30%;">
                        {{ action.reason }}
                      </td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-popover>

            <!-- changed input message -->
            <p class="d-inline ml-1 mb-0" v-if="changedInputs.length > 0">
              <b-icon
                class="ml-1"
                title=""
                icon="record-fill"
                style="color:orange!important"
                data-toggle="tooltip"
                data-placement="top"
                font-scale="0.5"
              ></b-icon
              ><b style="margin-left:0.3rem;color:#6e6b7b;font-size: 0.7rem;">{{
                $t("equity_bond.modals.inputs_written_red_inputs")
              }}</b>
            </p>
          </b-col>

          <b-col
            cols="4"
            md="4"
            xl="4"
            lg="4"
            sm="12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1 modal-btn"
              @click="resetAccountModal"
            >
              {{ $t("account_creation.modal.modal_cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1 modal-btn"
              @click="viewAccount"
            >
              {{ $t("account_creation.modal.view_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    {{/****END*** view modal*/ }}

    {{ /*account amend modal*/ }}
    <b-modal
      :no-enforce-focus="true"
      id="accountModal"
      v-model="accountAmendModal"
      :title="$t('account_creation.titles.account_amendment_form')"
      @hidden="resetAccountModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      no-close-on-backdrop
      :ok-title="$t('account_creation.modal.amend_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="amendAccount"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
    >
      <b-overlay :show="AccountModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="amendValidation">
            <b-form>
              <b-row class="mt-1">
                <b-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <b-card>
                    <b-row>
                      <!-- Account Name-->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.account_name')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.account_name')
                            "
                            rules="required"
                          >
                            <b-form-input
                              id="AccountName"
                              size="sm"
                              type="text"
                              :state="errors.length > 0 ? false : null"
                              @change="accountNameNumberCheck('amend')"
                              v-model="accountData.AccountName"
                              :disabled="
                                accountData.History &&
                                  accountData.History.VerifiedBy != null
                              "
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Account Number-->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.account_number')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.account_number')
                            "
                            rules="required|min:10|max:10"
                          >
                            <b-form-input
                              id="AccountNumber"
                              @blur="accountNumberCheck('amend')"
                              size="sm"
                              type="number"
                              v-model="accountData.AccountNumber"
                              :disabled="accountData.VerifiedBy != null"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Holder Type-->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.holder_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.holder_type')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="accountData.HolderType"
                              :label="
                                $t('account_creation.account_form.holder_type')
                              "
                              id="HolderType"
                              :clearable="false"
                              :options="holderTypes"
                              :disabled="
                                accountData.History &&
                                  accountData.History.VerifiedBy != null
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Setlement Type-->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.settlement_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'account_creation.account_form.settlement_type'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="accountData.SettlementType"
                              :label="
                                $t(
                                  'account_creation.account_form.settlement_type'
                                )
                              "
                              id="SettlementType"
                              :clearable="false"
                              :options="settlementTypes"
                              :disabled="
                                accountData.History &&
                                  accountData.History.VerifiedBy != null
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.tax_residency')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="accountData.TaxResidency"
                              :label="
                                $t(
                                  'account_creation.account_form.tax_residency'
                                )
                              "
                              id="TaxResidency"
                              :clearable="false"
                              :options="countryNames"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- UNCOMMENT WITH FX OPTION ACTIVATION -->

                    <!-- Client Comm Per Contract -->

                    <!-- <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('account_creation.account_form.client_comm_pcontract')"
                          
                        >
                        <cleave
                            id="ClientCommPerContract"
                            ref="ClientCommPerContract"
                            v-model="accountData.ClientCommPerContract"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('account_creation.account_form.client_comm_pcontract')
                            "
                          />
                        </b-form-group>
                      </b-col>
                    </b-row> -->

                    <b-row>
                      <!-- client id Type-->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group
                          :label="$t('account_creation.account_form.client_id')"
                        >
                          <v-select
                            v-model="accountData.ClientId"
                            label="fullname"
                            :reduce="(val) => val.id"
                            id="clientId"
                            :clearable="false"
                            :options="clientList"
                            class="compact_form_vue-multi-select"
                            multiple
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <b-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                  <b-card>
                    <!-- Display Name-->

                    <b-row>
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.display_name')
                          "
                        >
                          <b-form-input
                            id="DisplayName"
                            size="sm"
                            type="text"
                            v-model="accountData.DisplayName"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Phone Number-->
                        <b-form-group
                          :label="$t('account_creation.account_form.phone')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('account_creation.account_form.phone')"
                            rules="min:10|max:12"
                          >
                            <b-form-input
                              id="Phone1"
                              size="sm"
                              type="number"
                              v-model="accountData.Phone1"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Phone Number 2 -->
                        <b-form-group
                          :label="$t('account_creation.account_form.phone2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('account_creation.account_form.phone2')"
                            rules="min:10|max:12"
                          >
                            <b-form-input
                              id="Phone2"
                              size="sm"
                              type="number"
                              v-model="accountData.Phone2"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Contact Email-->

                        <b-form-group
                          :label="
                            $t('account_creation.account_form.contact_email')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.contact_email')
                            "
                            rules="email"
                          >
                            <b-form-input
                              id="ContactEmail"
                              size="sm"
                              :state="errors.length > 0 ? false : null"
                              type="email"
                              v-model="accountData.ContactEmail"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Email 2-->

                        <b-form-group
                          :label="$t('account_creation.account_form.email2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('account_creation.account_form.email2')"
                            rules="email"
                          >
                            <b-form-input
                              id="Email2"
                              size="sm"
                              :state="errors.length > 0 ? false : null"
                              type="email"
                              v-model="accountData.Email2"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <!-- Address-->
                        <b-form-group
                          :label="$t('account_creation.account_form.adress')"
                        >
                          <b-form-textarea
                            class="compact-textarea-lg"
                            rows="3"
                            size="sm"
                            id="Address"
                            type="textarea"
                            v-model="accountData.Address"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <!-- Client Notes-->
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.client_notes')
                          "
                        >
                          <b-form-textarea
                            class="compact-textarea-lg"
                            rows="3"
                            size="sm"
                            id="ClientNote"
                            type="textarea"
                            v-model="accountData.ClientNote"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>

        <template #overlay v-if="AccountModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />
              {{
                $t(
                  "account_creation.overlay_messages.account_amendment_message"
                )
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <!-- trade ok operation buttons -->

          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class="d-flex justify-content-start align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="
                accountData.History == null || accountData.History.length == 0
              "
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">Audit Log</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="focus"
              id="actions-popover"
              class="popover-body"
              style="min-width: 350px;"
            >
              <template #title>
                Audit Log
              </template>
              <div class="scrollable-popover">
                <b-table-simple hover small caption-top responsive bordered>
                  <b-tbody class="compact_form_actions-table">
                    <b-tr
                      v-for="(action, index) in accountData.History"
                      :key="index"
                    >
                      <td style="width: 20%;">
                        {{ action.type }}
                      </td>
                      <td style="width: 20%;">
                        {{ action.user }}
                      </td>
                      <td style="width: 30%;">
                        {{ action.date }}
                      </td>
                      <td style="width: 30%;">
                        {{ action.reason }}
                      </td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-popover>
          </b-col>

          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1 modal-btn"
              @click="resetAccountModal"
            >
              {{ $t("account_creation.modal.modal_cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1 modal-btn"
              @click="amendAccount"
            >
              {{ $t("account_creation.modal.amend_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    {{/****END*** amend modal*/ }}

    {{ /*account verify modal*/ }}
    <b-modal
      id="accountModal"
      v-model="accountVerifyModal"
      :title="$t('account_creation.titles.verify_account_form')"
      @hidden="resetAccountModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('account_creation.modal.verify_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="verifyAccount"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
    >
      <b-overlay :show="AccountModalLoading" rounded="sm" no-fade>
        <b-container>
          <b-form>
            <b-row class="mt-1">
              <b-col
                cols="12"
                xl="6"
                lg="6"
                md="12"
                sm="12"
                class="pl-0"
                style="padding-right:0.5rem!important"
              >
                <b-card>
                  <!-- Account Name-->

                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.account_name')
                        "
                      >
                        <b-form-input
                          id="AccountName"
                          size="sm"
                          type="text"
                          v-model="accountData.AccountName"
                          :disabled="true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Account Number-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.account_number')
                        "
                      >
                        <b-form-input
                          id="AccountNumber"
                          size="sm"
                          type="number"
                          v-model="accountData.AccountNumber"
                          :disabled="true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Holder Type-->
                      <b-form-group
                        :label="$t('account_creation.account_form.holder_type')"
                      >
                        <v-select
                          v-model="accountData.HolderType"
                          :label="
                            $t('account_creation.account_form.holder_type')
                          "
                          id="HolderType"
                          :clearable="false"
                          :options="holderTypes"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Setlement Type-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.settlement_type')
                        "
                      >
                        <v-select
                          v-model="accountData.SettlementType"
                          :label="
                            $t('account_creation.account_form.settlement_type')
                          "
                          id="SettlementType"
                          :clearable="false"
                          :options="settlementTypes"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.tax_residency')
                        "
                      >
                        <v-select
                          v-model="accountData.TaxResidency"
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                          id="TaxResidency"
                          :clearable="false"
                          :options="countryNames"
                          @option:selected="selectRate"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- UNCOMMENT WITH FX OPTION ACTIVATION -->

                  <!-- Client Comm Per Contract -->

                  <!-- <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('account_creation.account_form.client_comm_pcontract')"
                          
                        >
                        <cleave
                            id="ClientCommPerContract"
                            ref="ClientCommPerContract"
                            v-model="accountData.ClientCommPerContract"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('account_creation.account_form.client_comm_pcontract')
                            "
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row> -->

                  <b-row>
                    <!-- Holder Type-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('account_creation.account_form.client_id')"
                      >
                        <ul class="full-names-list">
                          <li
                            v-for="clientId in accountData.ClientId"
                            :key="clientId"
                          >
                            {{ getClientName(clientId) }}
                          </li>
                          <li
                            v-if="
                              accountData.ClientId == [] ||
                                accountData.ClientId == null
                            "
                          >
                            No Clients
                          </li>
                        </ul>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>

              <b-col
                cols="12"
                xl="6"
                lg="6"
                md="12"
                sm="12"
                class="pr-0"
                style="padding-left:0.5rem!important"
              >
                <b-card>
                  <!-- Display Name-->

                  <b-row>
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.display_name')
                        "
                      >
                        <b-form-input
                          id="DisplayName"
                          size="sm"
                          type="text"
                          v-model="accountData.DisplayName"
                          :disabled="true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Phone Number-->
                      <b-form-group
                        :label="$t('account_creation.account_form.phone')"
                      >
                        <b-form-input
                          id="Phone1"
                          size="sm"
                          type="tel"
                          v-model="accountData.Phone1"
                          :disabled="true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Phone Number 2 -->
                      <b-form-group
                        :label="$t('account_creation.account_form.phone2')"
                      >
                        <b-form-input
                          id="Phone2"
                          size="sm"
                          type="tel"
                          v-model="accountData.Phone2"
                          :disabled="true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Contact Email-->

                      <b-form-group
                        :label="
                          $t('account_creation.account_form.contact_email')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('account_creation.account_form.contact_email')
                          "
                          rules="email"
                        >
                          <b-form-input
                            id="ContactEmail"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            v-model="accountData.ContactEmail"
                            :disabled="true"
                          ></b-form-input>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                      <!-- Email 2-->

                      <b-form-group
                        :label="$t('account_creation.account_form.email2')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('account_creation.account_form.email2')"
                          rules="email"
                        >
                          <b-form-input
                            id="Email2"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            v-model="accountData.Email2"
                            :disabled="true"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <!-- Address-->
                      <b-form-group
                        :label="$t('account_creation.account_form.adress')"
                      >
                        <b-form-textarea
                          class="compact-textarea-lg"
                          rows="3"
                          size="sm"
                          id="Address"
                          type="textarea"
                          v-model="accountData.Address"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <!-- Client Notes-->
                      <b-form-group
                        :label="
                          $t('account_creation.account_form.client_notes')
                        "
                      >
                        <b-form-textarea
                          class="compact-textarea-lg"
                          rows="3"
                          size="sm"
                          id="ClientNote"
                          type="textarea"
                          v-model="accountData.ClientNote"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </b-container>

        <template #overlay v-if="AccountModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t(
                  "account_creation.overlay_messages.account_verifying_message"
                )
              }}
            </p>
          </div>
        </template>
      </b-overlay>

      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <!-- trade ok operation buttons -->

          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class="d-flex justify-content-start align-items-start"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="
                accountData.History == null || accountData.History.length == 0
              "
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">Audit Log</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="focus"
              id="actions-popover"
              class="popover-body"
            >
              <template #title>
                Audit Log
              </template>
              <div class="scrollable-popover">
                <b-table-simple hover small caption-top responsive bordered>
                  <b-tbody
                    class="compact_form_actions-table"
                    style="min-width: 350px;"
                  >
                    <b-tr
                      v-for="(action, index) in accountData.History"
                      :key="index"
                    >
                      <td style="width: 20%;">
                        {{ action.type }}
                      </td>
                      <td style="width: 20%;">
                        {{ action.user }}
                      </td>
                      <td style="width: 30%;">
                        {{ action.date }}
                      </td>
                      <td style="width: 30%;">
                        {{ action.reason }}
                      </td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-popover>
          </b-col>

          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1 modal-btn"
              @click="resetAccountModal"
            >
              {{ $t("account_creation.modal.modal_cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1 modal-btn"
              @click="verifyAccount"
            >
              {{ $t("account_creation.modal.verify_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    {{/****END*** verify modal*/ }}

    {{ /*create code modal*/ }}
    <b-modal
      id="accountModal"
      v-model="createCodeModal"
      :title="$t('account_creation.titles.create_new_code')"
      @hidden="resetAccountModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('account_creation.modal.create_code_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="createNewAccountCode"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
    >
      <b-overlay :show="createCodeModalLoading" rounded="sm" no-fade>
        <validation-observer ref="createCodeValidation">
          <b-container>
            <b-form>
              <b-row class="mt-1">
                <b-col cols="12" xl="6" lg="6" md="12" sm="12">
                  <b-card>
                    <!-- Account Name-->

                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.account_name')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.account_name')
                            "
                            rules="required"
                          >
                            <b-form-input
                              id="AccountName"
                              size="sm"
                              type="text"
                              v-model="createCodeAccountData.AccountName"
                              :disabled="true"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Account Number-->
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.account_number')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.account_number')
                            "
                            rules="required"
                          >
                            <b-form-input
                              id="AccountNumber"
                              size="sm"
                              type="number"
                              v-model="createCodeAccountData.AccountNumber"
                              :disabled="true"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Account Ccy-->
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.account_ccy')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.account_ccy')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="createCodeAccountData.AccountCcy"
                              id="AccountCcy"
                              :clearable="false"
                              :options="optAccountCcy"
                              :reduce="(val) => val.Currency"
                              label="Currency"
                              placeholder="Account Currency"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Account Type-->
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.account_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.account_type')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="createCodeAccountData.AccountType"
                              :placeholder="
                                $t('account_creation.account_form.account_type')
                              "
                              id="AccountType"
                              :clearable="false"
                              :options="optAccountTypes"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Account Code-->
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.account_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.account_type')
                            "
                            rules="required"
                          >
                            <b-form-input
                              id="AccountCode"
                              size="sm"
                              v-model="createCodeAccountData.AccountCode"
                              :disabled="true"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                        >
                          <v-select
                            v-model="accountData.TaxResidency"
                            :label="
                              $t('account_creation.account_form.tax_residency')
                            "
                            id="TaxResidency"
                            :clearable="false"
                            :options="countryNames"
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- UNCOMMENT WITH FX OPTION ACTIVATION -->

                    <!-- Client Comm Per Contract -->

                    <!-- <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('account_creation.account_form.client_comm_pcontract')"
                          
                        >
                        <cleave
                            id="ClientCommPerContract"
                            ref="ClientCommPerContract"
                            v-model="accountData.ClientCommPerContract"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('account_creation.account_form.client_comm_pcontract')
                            "
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row> -->
                  </b-card>
                </b-col>

                <b-col cols="12" xl="6" lg="6" md="12" sm="12">
                  <b-card>
                    <!-- Display Name-->
                    <b-row>
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.display_name')
                          "
                        >
                          <b-form-input
                            id="DisplayName"
                            size="sm"
                            type="text"
                            v-model="createCodeAccountData.DisplayName"
                            :disabled="true"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Phone Number-->
                        <b-form-group
                          :label="$t('account_creation.account_form.phone')"
                        >
                          <b-form-input
                            id="Phone1"
                            size="sm"
                            type="tel"
                            v-model="createCodeAccountData.Phone1"
                            :disabled="true"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Phone Number 2 -->
                        <b-form-group
                          :label="$t('account_creation.account_form.phone2')"
                        >
                          <b-form-input
                            id="Phone2"
                            size="sm"
                            type="tel"
                            v-model="createCodeAccountData.Phone2"
                            :disabled="true"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Contact Email-->

                        <b-form-group
                          :label="
                            $t('account_creation.account_form.contact_email')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('account_creation.account_form.contact_email')
                            "
                          >
                            <b-form-input
                              id="ContactEmail"
                              size="sm"
                              :state="errors.length > 0 ? false : null"
                              type="email"
                              v-model="createCodeAccountData.ContactEmail"
                              :disabled="true"
                            ></b-form-input>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Email 2-->

                        <b-form-group
                          :label="$t('account_creation.account_form.email2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="E-mail 2"
                          >
                            <b-form-input
                              id="Email2"
                              size="sm"
                              :state="errors.length > 0 ? false : null"
                              type="email"
                              v-model="createCodeAccountData.Email2"
                              :disabled="true"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Address-->
                        <b-form-group
                          :label="$t('account_creation.account_form.adress')"
                        >
                          <b-form-textarea
                            class="compact-textarea-lg"
                            rows="3"
                            size="sm"
                            id="Address"
                            type="textarea"
                            v-model="createCodeAccountData.Address"
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- Client Notes-->
                        <b-form-group
                          :label="
                            $t('account_creation.account_form.client_notes')
                          "
                        >
                          <b-form-textarea
                            class="compact-textarea-lg"
                            rows="3"
                            size="sm"
                            id="ClientNote"
                            type="textarea"
                            v-model="createCodeAccountData.ClientNote"
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </b-container>
        </validation-observer>
        <template #overlay v-if="createCodeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />
              {{
                $t(
                  "account_creation.overlay_messages.account_currency_creating_message"
                )
              }}
            </p>
          </div>
        </template>
      </b-overlay>

      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <!-- trade ok operation buttons -->

          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class="d-flex justify-content-start align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="
                accountData.History == null || accountData.History.length == 0
              "
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">Audit Log</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="focus"
              id="actions-popover"
              class="popover-body"
            >
              <template #title>
                Audit Log
              </template>
              <div class="scrollable-popover">
                <b-table-simple hover small caption-top responsive bordered>
                  <b-tbody class="compact_form_actions-table">
                    <b-tr
                      v-for="(action, index) in accountData.History"
                      :key="index"
                    >
                      <td style="width: 20%;">
                        {{ action.type }}
                      </td>
                      <td style="width: 20%;">
                        {{ action.user }}
                      </td>
                      <td style="width: 30%;">
                        {{ action.date }}
                      </td>
                      <td style="width: 30%;">
                        {{ action.reason }}
                      </td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-popover>
          </b-col>

          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1 modal-btn"
              @click="resetAccountModal"
            >
              {{ $t("account_creation.modal.modal_cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1 modal-btn"
              @click="createNewAccountCode"
            >
              {{ $t("account_creation.modal.create_code_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    {{/****END*** create code modal*/ }}
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
  BPopover,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import accountStoreModule from "./accountStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import "cleave.js/dist/addons/cleave-phone.us";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import VueContext from "vue-context";
import Cleave from "vue-cleave-component";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    BTooltip,
    VueContext,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const ACCOUNT_APP_STORE_MODULE_NAME = "account";
    // Register module
    if (!store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME))
      store.registerModule(ACCOUNT_APP_STORE_MODULE_NAME, accountStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME))
        store.unregisterModule(ACCOUNT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      countryNames: null,
      allAccounts: [],
      lastAccountNumbers: [],
      accountCreateModal: false,
      accountSearchModal: false,
      accountViewModal: false,
      accountAmendModal: false,
      accountVerifyModal: false,
      createCodeModal: false,
      allTeams: [],
      filterCreateModal: null,
      filterCreateModalOn: ["TeamName"],
      updatePermloading: false,
      AccountModalLoading: false,
      createCodeModalLoading: false,
      confrimPassword: null,
      amendedReasonText: null,
      preAccount: null,
      phoneNumberCheck: true,
      accountData: {
        AccountName: null,
        AccountNumber: null,
        HolderType: "INDIVIDUAL",
        SettlementType: "CUSTODIAN",
        DisplayName: null,
        Phone1: null,
        Phone2: null,
        ContactEmail: null,
        Email2: null,
        Address: null,
        ID: null,
        AmendedBy: null,
        AmendmentDate: null,
        AmendmentReason: null,
        AmendmentTime: null,
        ClientNote: null,
        CreatedBy: null,
        CreationDate: null,
        CreationTime: null,
        VerificationDate: null,
        VerificationTime: null,
        VerifiedBy: null,
        TaxResidency: "United Kingdom",
        ClientCommPerContract: null,
        ClientId: [],
      },
      createCodeAccountData: {
        AccountName: null,
        AccountNumber: null,
        AccountCcy: "CURRENT",
        AccountType: null,
        AccountCode: null,
        HolderType: null,
        DisplayName: null,
        Phone1: null,
        Phone2: null,
        ContactEmail: null,
        Email2: null,
        Address: null,
        ID: null,
        ClientNote: null,
      },
      optAccountCcy: [],
      updateStatusList: [],
      optAccountTypes: ["CURRENT", "COLLATERAL", "LOAN", "MTM"],
      holderTypes: ["INDIVIDUAL", "CORPORATE", "COUNTERPARTY"],
      settlementTypes: ["DVP", "CUSTODIAN"],
      filterOn: ["AccountName"],
      sortByDesc: true,
      sortBy: "ID",
      optHolderTypes: [
        { title: "all", value: "" },
        { title: "INDIVIDUAL", value: "INDIVIDUAL" },
        { title: "CORPORATE", value: "CORPORATE" },
      ],
      optSettlementTypes: [
        { title: "all", value: "" },
        { title: "DVP", value: "DVP" },
        { title: "CUSTODIAN", value: "CUSTODIAN" },
      ],
      optStatuses: [
        { title: "all", value: "" },
        { title: "Verified", value: false },
        { title: "Unverified", value: true },
      ],
      tableColumns: [
        {
          key: "ID",
          label: "ID",
          class: "w-0 table-padding sticky-column",
          thClass: "sticky-column text-center",
          tdClass: "text-center ID_background",
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("account_creation.table.status"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "AccountName",
          label: this.$t("account_creation.table.account_name"),
          sortable: true,
          class: "text-center table-padding",
        },
        {
          key: "AccountNumber",
          label: this.$t("account_creation.table.account_number"),
          sortable: false,
          class: "text-center table-padding",
        },

        {
          key: "HolderType",
          label: this.$t("account_creation.table.holder_type"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "SettlementType",
          label: this.$t("account_creation.table.settlement_type"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "TaxResidency",
          label: this.$t("account_creation.table.tax_residency"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "ClientCommPerContract",
          label: this.$t("account_creation.table.client_comm_pcontract"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "CreatedBy",
          label: this.$t("account_creation.table.created_by"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "CreationDate",
          label: this.$t("account_creation.table.creation_date"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "CreationTime",
          label: this.$t("account_creation.table.creation_time"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "VerifiedBy",
          label: this.$t("account_creation.table.verified_by"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "VerificationDate",
          label: this.$t("account_creation.table.verification_date"),
          sortable: false,
          class: "text-center table-padding",
        },

        {
          key: "VerificationTime",
          label: this.$t("account_creation.table.verification_time"),
          sortable: false,
          class: "text-center table-padding",
        },
        //   {

        //   key: "TradeActions",
        //   label: "Actions",
        //   class: "text-center",
        //   thStyle:"background-color:#ebe9f1;color:black;border: 1px solid #ebe9f1;"
        // },
      ],
      multiSelectProcessModalTableColumns: [
        {
          key: "ID",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.id"
          ),
          class: "text-center",
          thStyle: "width: 20%",
        },
        {
          key: "AccountNumber",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.accountNumber"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 20%",
        },
        {
          key: "info",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.info"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 20%",
        },
        {
          key: "reason",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.reason"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 40%",
        },
      ],
      perPage: 15,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [15, 25, 50, 100],
      filter: null,
      clientList: [],
      changedInputs: [],
      loading: false,
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      filters: {
        AccountName: "",
        AccountNumber: "",
        HolderType: "",
      },
      onlyArchived: false,
      showColumnsFilter: false,
      showMultiplecheckboks: false,
      selectedMultipleTrades: [],
      multiSelectListStatusData: [],
      showMultiSelectProcessModal: false,
      multiSelectProcessModalTableData: [],
      seeCreateNewAccountButton: false,
      seeVerifyButton: false,
      seeBringAccountButton: false,
      windowHeight: window.innerHeight,
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          // novos
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
          // fim novos
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },

      defaultRate: null,
      rates: [],
      selectedRate: null,
    };
  },
  computed: {
    ...mapGetters(["authUser"]),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    filteredTrades() {
      const filtered = this.allAccounts.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key]).includes(this.filters[key])
        );
      });
      return filtered.length > 0 ? filtered : filtered;
    },
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.getAllEntries();
    this.getCountries();
    this.getClients();

    // this.debouncedGetWindowHeight = debounce(this.getWindowHeight, 300);
    // window.addEventListener("resize", this.debouncedGetWindowHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedGetWindowHeight);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getClientName(clientId) {
      // Find the client with the given clientId in the clientList
      const client = this.clientList.find((client) => client.id === clientId);
      // Return the full name of the client
      return client ? client.fullname : ""; // Return empty string if client is not found
    },

    getWindowHeight() {
      this.windowHeight = window.innerHeight;
      this.updateStickyHeaderHeight();
    },
    updateStickyHeaderHeight() {
      this.$nextTick(() => {
        const headerElements = document.getElementsByClassName(
          "b-table-sticky-header"
        );
        if (headerElements.length > 0) {
          const newHeight = window.innerHeight - 550;
          this.stickyHeaderStyle = `height: ${newHeight}px`;
        }
      });
    },
    onRowSelected(row) {
      row._showDetails = !row._showDetails;
    },

    showTradeControlsPopover(targetTrade, trade) {
      if (trade) {
        // set default
        this.seeCreateNewAccountButton = false;
        this.seeVerifyButton = false;
        this.seeBringAccountButton = false;

        // set suitable flow buttons

        // see verify button
        if (
          trade.VerifiedBy == null &&
          trade.VerificationTime == null &&
          trade.VerificationDate == null
        ) {
          this.seeVerifyButton = true;
        }

        // see new account buttons
        if (
          trade.VerifiedBy != null &&
          trade.VerificationTime != null &&
          trade.VerificationDate != null
        ) {
          this.seeCreateNewAccountButton = true;
        }

        // see new account buttons
        if (
          trade.VerifiedBy != null &&
          trade.VerificationTime != null &&
          trade.VerificationDate != null
        ) {
          this.seeBringAccountButton = true;
        }
      }

      this.$root.$emit("bv::show::popover", targetTrade);
    },
    optionRightClicked(click) {
      if (click == "showColumnsFilter") {
        this.showColumnsFilter = !this.showColumnsFilter;
      }

      if (click == "showMultiplecheckboks") {
        this.showMultiplecheckboks = !this.showMultiplecheckboks;
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades = [];

        // this.tableColumns[1].class = this.showMultiplecheckboks
        //   ? "text-center"
        //   : "text-center d-none";
        this.multiSelectListStatusData = [];
        _.mapValues(this.allEquityBondsData, (v) => (v.selectedTrade = false));
      }
    },
    multiSelectRow(check, trade) {
      if (check) {
        //add right click check true trades
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades.push(trade);
      } else {
        // remove false check trades
        let clonedTrades = JSON.parse(
          JSON.stringify(this.selectedMultipleTrades)
        );
        _.remove(clonedTrades, {
          id: trade.id,
        });

        this.selectedMultipleTrades = clonedTrades;
      }
    },
    multipleChangeOprFunc(status) {
      let tradesCount = null;
      let trades = [];
      if (status == "Verify") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          VerifiedBy: null,
          VerificationTime: null,
          VerificationDate: null,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          VerifiedBy: null,
          VerificationTime: null,
          VerificationDate: null,
        });
      }

      if (this.selectedMultipleTrades.length > 0 && status == "archiveTrades") {
        let data = this.selectedMultipleTrades.map((e) => {
          return e.AccountNumber;
        });
        this.archiveMultipleAccounts(data);
      }

      if (trades.length > 0 && status) {
        if (status == "copyTrades") {
          //this.tradeMultipleSelectCopyOpr(tradesCount, trades);
        } else {
          this.tradeMultipleChangeStatusOpr(tradesCount, trades, status);
        }
      }
    },

    tradeMultipleChangeStatusOpr(tradesCount, trades, status) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_status_title"),
        text:
          self.$t("multiCheckBoxTitles.trades_count_message_1") +
          tradesCount +
          self.$t("multiCheckBoxTitles.trades_count_message_2"),
        input:
          status == "Revers Inititated" || status == "Cancel" ? "textarea" : "",
        inputPlaceholder: self.$t(
          "fx_spot_forward_swap.reason.please_enter_reversal_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.change_status"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              resolve();
            } else {
              resolve(
                self.$t("fx_spot_forward_swap.reason.you_need_write_reason")
              );
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;
          store
            .dispatch("account/tradeMultipleChangeAccountStatusOpr", {
              auth: self.user,
              trades: trades,
              status: status,
            })
            .then((res) => {
              self.loading = false;
              self.multiSelectProcessModalTableData = [];

              if (res.data.successProcesses.length > 0) {
                self.multiSelectProcessModalTableData.push(
                  ...res.data.successProcesses
                );
              }

              if (res.data.failedProcesses.length > 0) {
                self.multiSelectProcessModalTableData.push(
                  ...res.data.failedProcesses
                );
              }
              self.showMultiSelectProcessModal = true;

              if (res.data.failedProcesses.length > 0) {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesWarningMessage();
              } else {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesSuccessMessage();
                _.mapValues(
                  this.allEquityBondsData,
                  (v) => (v.selectedTrade = false)
                );
                self.okMultiSelectProcessModal();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    // tradeMultipleSelectCopyOpr(tradesCount, trades) {
    //   const self = this;
    //   this.$swal({
    //     title: self.$t("multiCheckBoxTitles.change_copy_title"),
    //     text:
    //       tradesCount +
    //       self.$t("multiCheckBoxTitles.trades_copy_message"),
    //     //icon: "warning",
    //     showCancelButton: true,
    //     showLoaderOnConfirm: true,
    //     inputAttributes: {
    //       style: "margin-top:5px!important",
    //     },
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: self.$t("multiCheckBoxTitles.copy_trades"),
    //     cancelButtonText: self.$t("general_buttons.cancel"),
    //     customClass: {
    //       confirmButton: "btn btn-primary mr-1",
    //       cancelButton: "btn btn-danger",
    //       denyButton: "btn btn-warning",
    //       text: "mb-1",
    //     },
    //     showClass: {
    //       popup: "animate__animated animate__fadeInDown",
    //     },
    //     hideClass: {
    //       popup: "animate__animated animate__fadeOutUp",
    //     },
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       self.loading = true;

    //       // set trade data
    //         if (trades.length > 0) {
    //           var setTradesData = [];
    //           trades.forEach((trade) => {
    //             setTradesData.push(self.setTradeData(trade));
    //           });
    //         }
    //         trades = setTradesData;
    //       store
    //         .dispatch("account/tradeMultipleSelectTransactionCopyOpr", {
    //           auth: self.user,
    //           trades: trades,
    //         })
    //         .then((res) => {
    //           self.loading = false;
    //           self.selectedMultipleTrades = [];

    //           if(res.data.failedCopyOpr.length > 0){
    //             this.multiSelectCopyWarningMessage()
    //             this.okMultiSelectProcessModal()
    //           } else if(res.data.info == 'not_found'){
    //             this.wrongToastMessage(res.data.message)
    //           } else {
    //             self.multiSelectAllTradesCopySuccessMessage();
    //             this.okMultiSelectProcessModal()
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     }
    //   });
    // },

    okMultiSelectProcessModal() {
      this.showMultiSelectProcessModal = false;
      this.multiSelectProcessModalTableData = [];
      _.mapValues(this.allEquityBondsData, (v) => (v.selectedTrade = false));

      this.getAllEntries();
    },

    //ALL DATAS
    getAllEntries() {
      this.loading = true;
      this.onlyArchived = false;
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("account/refreshPortfolioForClientAccounts", this.user)
            .then((res) => {
              this.allAccounts = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    getCountries() {
      this.loading = true;
      store
        .dispatch("account/getTaxRates")
        .then((res) => {
          this.countryNames = res.data.rates.map((e) => e.Residency);
          this.rates = res.data.rates;
          this.defaultRate = res.data.defaultRate;
          this.selectRate();
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
          this.loading = false;
        });
    },

    getLastAccountNumbers() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        store
          .dispatch("account/getLastAccountNumbers")
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            this.databaseErrorMessage();
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    getClients() {
      this.loading = true;
      store
        .dispatch("account/getClients")
        .then((res) => {
          this.clientList = res.data;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
          this.loading = false;
        });
    },

    selectRate() {
      const filteredRates = this.rates.filter(
        (e) => e.Residency === this.accountData.TaxResidency
      );
      this.selectedRate = filteredRates[0].Rate
        ? filteredRates[0].Rate
        : this.defaultRate;
    },

    //VERIFIED DATAS
    verifiedEntries() {
      this.loading = true;

      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          store
            .dispatch(
              "account/refreshPortfolioForClientAccountsVerified",
              this.user
            )
            .then((res) => {
              this.allAccounts = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //UNVERIFIED DATAS
    unverifiedEntries() {
      this.loading = true;
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          store
            .dispatch(
              "account/refreshPortfolioForClientAccountsNotVerified",
              this.user
            )
            .then((res) => {
              this.allAccounts = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //AMEND BUTTON
    amendEntry(val) {
      this.$checkAccessRight("AccountCreation", "Amend").then((res) => {
        if (res.data.valid) {
          if (val.id != undefined) {
            this.accountData.password = this.$encryptPassword(
              this.accountData.password
            );
            this.AccountModalLoading = true;
            this.loading = true;
            store
              .dispatch("account/selectAccNoFromCustomer", {
                auth: this.user,
                accountNumber: val.AccountNumber,
                ID: val.id,
              })
              .then((res) => {
                this.accountData = {
                  ID: res.data.id,
                  AccountName: res.data.AccountName,
                  AccountNumber: res.data.AccountNumber,
                  HolderType: res.data.HolderType,
                  SettlementType: res.data.SettlementType,
                  TaxResidency: res.data.TaxResidency,
                  ClientCommPerContract: res.data.ClientCommPerContract,
                  DisplayName: res.data.DisplayName,
                  Phone1: res.data.Phone1,
                  Phone2: res.data.Phone2,
                  ContactEmail: res.data.ContactEmail,
                  Email2: res.data.Email2,
                  Address: res.data.Address,
                  AmendedBy: res.data.AmendedBy,
                  AmendmentDate: res.data.AmendmentDate,
                  AmendmentReason: res.data.AmendmentReason,
                  AmendmentTime: res.data.AmendmentTime,
                  ClientNote: res.data.ClientNote,
                  CreatedBy: res.data.CreatedBy,
                  CreationDate: res.data.CreationDate,
                  CreationTime: res.data.CreationTime,
                  VerificationDate: res.data.VerificationDate,
                  VerificationTime: res.data.VerificationTime,
                  VerifiedBy: res.data.VerifiedBy,
                  History: res.data.History,
                  ClientId: res.data.ClientId,
                };
                this.preAccount = JSON.parse(JSON.stringify(this.accountData));
                this.loading = false;
                this.AccountModalLoading = false;
                this.accountAmendModal = true;
                if (this.accountData.History.VerifiedBy != null) {
                  this.verfiedAmendMessage();
                }
              })
              .catch((error) => {
                this.AccountModalLoading = false;
                this.loading = false;
                console.log(error);
              });
          } else {
            this.AccountIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //AMEND POPUP OK
    amendAccount(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (!_.isEqual(this.preAccount, this.accountData)) {
        this.$checkAccessRight("AccountCreation", "Amend").then((res) => {
          if (res.data.valid) {
            this.$refs.amendValidation.validate().then((success) => {
              if (success) {
                let checkAccountName = 0;
                let checkAccountNumber = 0;

                if (
                  this.preAccount.AccountName != this.accountData.AccountName
                ) {
                  checkAccountName = 1;
                }

                if (
                  this.preAccount.AccountNumber !=
                  this.accountData.AccountNumber
                ) {
                  checkAccountNumber = 1;
                }

                this.AccountModalLoading = true;

                store
                  .dispatch("account/checkAccountDetailsExist", {
                    auth: this.user,
                    accountData: this.accountData,
                    checkAccountName: checkAccountName,
                    checkAccountNumber: checkAccountNumber,
                  })
                  .then((res) => {
                    this.AccountModalLoading = false;
                    if (res.data == "Pass") {
                      this.accountAmendReasonText().then((res) => {
                        if (res) {
                          this.accountData.AmendmentReason = this.amendedReasonText;
                          this.accountData.AmendmentDate = moment().format(
                            "YYYY-MM-DD"
                          );
                          this.accountData.AmendmentTime = moment().format(
                            "HH:mm:ss"
                          );
                          this.accountData.AmendedBy =
                            this.user.name + " " + this.user.surname;
                          store
                            .dispatch("account/updateAmendAccount", {
                              auth: this.user,
                              accountData: this.accountData,
                            })
                            .then((res) => {
                              if (res.data.info == "Pass") {
                                this.AccountModalLoading = false;
                                this.accountAmendModal = false;
                                this.resetAccountModal();
                                this.getAllEntries();
                              } else {
                                this.errorMessage(res.data.message);
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        } else {
                          this.amendOpearionNotDoneMessage();
                        }
                      });
                    } else {
                      this.warningMessage(res.data.message);
                    }
                  })
                  .catch((error) => {
                    this.AccountModalLoading = false;
                    this.accountCreateModal = false;
                    this.getAllEntries();
                    console.log(error);
                  });
              } else {
                let notEnteredDatas = "";

                let checkRequiredDatas = _.map(
                  this.$refs.amendValidation.errors,
                  (value, key) => ({ key, value })
                );

                if (checkRequiredDatas.length > 0) {
                  let first = true;
                  checkRequiredDatas.forEach((r) => {
                    if (r.value.length > 0) {
                      if (first) {
                        notEnteredDatas += r.key;
                        first = false;
                      } else {
                        notEnteredDatas += "," + r.key;
                        first = false;
                      }
                    }
                  });
                  this.checkRequiredInputsMessage(notEnteredDatas, 20000);
                }
              }
            });
          } else {
            this.notAuthToastMessage();
          }
        });
      } else {
        this.noChangesMessage();
      }
    },

    async accountAmendReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: this.$t("account_creation.reason.amendment_reason"),
        text: this.$t("account_creation.reason.please_enter_amendment_reason"),
        input: "textarea",
        inputPlaceholder: "Please enter reason for amend.",
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("account_creation.reason.submit"),
        cancelButtonText: this.$t("account_creation.reason.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              self.amendedReasonText = reason;
              resolve();
            } else {
              resolve(this.$t("account_creation.reason.you_need_write_reason"));
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },

    //VERIFY BUTTON
    verifySelectedAccount(item) {
      this.$checkAccessRight("AccountCreation", "Verify").then((res) => {
        if (res.data.valid) {
          if (item.id != 0) {
            if (item.AccountNumber != null && item.AccountNumber != "") {
              this.loading = true;
              store
                .dispatch("account/checkVerifyAccount", {
                  auth: this.user,
                  accountNumber: item.AccountNumber,
                })
                .then((res) => {
                  if (res.data.info == "not_verified") {
                    store
                      .dispatch("account/selectAccNoFromCustomer", {
                        auth: this.user,
                        accountNumber: item.AccountNumber,
                        ID: item.id,
                      })
                      .then((res) => {
                        this.accountData = {
                          ID: res.data.id,
                          AccountName: res.data.AccountName,
                          AccountNumber: res.data.AccountNumber,
                          HolderType: res.data.HolderType,
                          SettlementType: res.data.SettlementType,
                          TaxResidency: res.data.TaxResidency,
                          ClientCommPerContract: res.data.ClientCommPerContract,
                          DisplayName: res.data.DisplayName,
                          Phone1: res.data.Phone1,
                          Phone2: res.data.Phone2,
                          ContactEmail: res.data.ContactEmail,
                          Email2: res.data.Email2,
                          Address: res.data.Address,
                          AmendedBy: res.data.AmendedBy,
                          AmendmentDate: res.data.AmendmentDate,
                          AmendmentReason: res.data.AmendmentReason,
                          AmendmentTime: res.data.AmendmentTime,
                          ClientNote: res.data.ClientNote,
                          CreatedBy: res.data.CreatedBy,
                          CreationDate: res.data.CreationDate,
                          CreationTime: res.data.CreationTime,
                          VerificationDate: res.data.VerificationDate,
                          VerificationTime: res.data.VerificationTime,
                          VerifiedBy: res.data.VerifiedBy,
                          History: res.data.History,
                          ClientId: res.data.ClientId,
                        };
                        this.loading = false;
                        this.AccountModalLoading = false;
                        this.accountVerifyModal = true;
                      })
                      .catch((error) => {
                        this.AccountModalLoading = false;
                        this.loading = false;
                        console.log(error);
                      });
                  } else {
                    this.verifyMessage();
                    this.loading = false;
                  }
                })
                .catch((error) => {
                  this.AccountModalLoading = false;
                  this.loading = false;
                  console.log(error);
                });
            } else {
              this.accountNumberNotFoundMessage();
            }
          } else {
            this.AccountIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //VERIFY POPUP OK
    verifyAccount(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("AccountCreation", "Verify").then((res) => {
        if (res.data.valid) {
          this.AccountModalLoading = true;
          store
            .dispatch("account/verifyAccount", {
              auth: this.user,
              accountData: this.accountData,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                this.successVerifiedMessage();

                if (res.data.insertAccount == 1) {
                  this.successInsertAccountMessage(
                    res.data.insertAccountMessage
                  );
                }
                this.AccountModalLoading = false;
                this.accountVerifyModal = false;
                this.getAllEntries();
              } else {
                this.AccountModalLoading = false;
                if (res.data.info == "error") {
                  this.errorMessage(
                    this.$t("account_creation.messages.verify_account_error")
                  );
                } else {
                  this.errorMessage(res.data.message);
                }
              }
            })
            .catch((error) => {
              this.AccountModalLoading = false;
              this.getAllEntries();

              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    accountNameNumberCheck(entry) {
      let checkAccountName = 0;
      let checkAccountNumber = 0;

      return new Promise((resolve, reject) => {
        if (entry == "amend") {
          if (this.preAccount.AccountName != this.accountData.AccountName) {
            checkAccountName = 1;
          }

          if (this.preAccount.AccountNumber != this.accountData.AccountNumber) {
            checkAccountNumber = 1;
          }
        } else if (entry == "create") {
          if (this.accountData.AccountName != null) {
            checkAccountName = 1;
          }

          if (this.accountData.AccountNumber) {
            checkAccountNumber = 1;
          }
        }

        if (
          this.accountData.AccountName != null &&
          this.accountData.AccountName != undefined &&
          this.accountData.AccountName != ""
        ) {
          this.AccountModalLoading = true;
          store
            .dispatch("account/accountDetailsExist", {
              auth: this.user,
              accountName: this.accountData.AccountName,
              accountNumber: this.accountData.AccountNumber,
              checkAccountName: checkAccountName,
              checkAccountNumber: checkAccountNumber,
            })
            .then((res) => {
              this.AccountModalLoading = false;
              if (res.data.info == "exist_name") {
                this.accountData.AccountName = null;
                this.wrongToastMessage(
                  this.$t("account_creation.messages.account_number_exist_name")
                );
                resolve(false);
              } else if (res.data.info == "exist_number") {
                this.accountData.AccountNumber = null;
                this.fillAutoAccountNumber();
                this.wrongToastMessage(
                  this.$t(
                    "account_creation.messages.account_number_exist_number"
                  )
                );
                resolve(false);
              }
            })
            .catch((error) => {
              this.AccountModalLoading = false;

              console.log(error);
            });
        } else if (
          this.accountData.AccountNumber != null &&
          this.accountData.AccountNumber != undefined &&
          this.accountData.AccountNumber != ""
        ) {
          this.AccountModalLoading = true;
          store
            .dispatch("account/accountNumberExist", {
              auth: this.user,
              accountNumber: this.accountData.AccountNumber,
              checkAccountNumber: checkAccountNumber,
              entry: entry,
            })
            .then((res) => {
              this.AccountModalLoading = false;
              if (res.data.info == "exist_number") {
                this.accountData.AccountNumber = null;
                this.fillAutoAccountNumber();
                this.wrongToastMessage(
                  this.$t(
                    "account_creation.messages.account_number_exist_number"
                  )
                );
                resolve(false);
              }
            })
            .catch((error) => {
              this.AccountModalLoading = false;

              console.log(error);
            });
        }

        resolve(true);
      });
    },

    async fillAutoAccountNumber() {
      this.lastAccountNumbers = await this.getLastAccountNumbers();

      if (this.lastAccountNumbers.length > 0) {
        this.accountData.AccountNumber = 0;
        this.lastAccountNumbers.forEach((element) => {
          if (Number(element.AccountNumber) >= this.accountData.AccountNumber) {
            this.accountData.AccountNumber = Number(element.AccountNumber) + 1;
          }
        });

        if (String(this.accountData.AccountNumber).length < 10) {
          for (
            let i = String(this.accountData.AccountNumber).length;
            i < 10;
            i++
          ) {
            this.accountData.AccountNumber =
              "0" + String(this.accountData.AccountNumber);
          }
        }
      }
    },

    accountNumberCheck(val) {
      if (
        this.accountData.AccountNumber != null &&
        this.accountData.AccountNumber != 0 &&
        this.accountData.AccountNumber != ""
      ) {
        this.accountData.AccountNumber = this.accountData.AccountNumber.replace(
          ".",
          ""
        );

        this.accountData.AccountNumber = this.accountData.AccountNumber.replace(
          "-",
          ""
        );

        if (this.accountData.AccountNumber.length > 10) {
          this.wrongToastMessage(
            this.$t("account_creation.messages.please_enter_less_than_digit")
          );
          this.accountData.AccountNumber = null;
        } else if (this.accountData.AccountNumber.length < 10) {
          for (let i = this.accountData.AccountNumber.length; i < 10; i++) {
            this.accountData.AccountNumber =
              "0" + this.accountData.AccountNumber;
          }

          this.accountNameNumberCheck(val);
        } else {
          this.accountNameNumberCheck(val);
        }
      }
    },
    checkPhoneNumber() {
      this.phoneNumberCheck = true;
      if (this.accountData.Phone1 != null && this.accountData.Phone2 != null) {
        if (this.accountData.Phone1 != this.accountData.Phone2) {
          this.phoneNumberCheck = false;
          this.phoneNumberMessage(
            this.$t("account_creation.messages.please_add_equal_phone_number")
          );
        }
      }
    },

    //****************CREATE START
    createAccountModal() {
      this.$checkAccessRight("AccountCreation", "Save").then(async (res) => {
        if (res.data.valid) {
          this.accountData.AccountNumber = 0;
          this.lastAccountNumbers = await this.getLastAccountNumbers();

          this.lastAccountNumbers.forEach((element) => {
            if (
              Number(element.AccountNumber) >= this.accountData.AccountNumber
            ) {
              this.accountData.AccountNumber =
                Number(element.AccountNumber) + 1;
            }
          });

          if (String(this.accountData.AccountNumber).length < 10) {
            for (
              let i = String(this.accountData.AccountNumber).length;
              i < 10;
              i++
            ) {
              this.accountData.AccountNumber =
                "0" + String(this.accountData.AccountNumber);
            }
          }
          this.accountCreateModal = true;
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    async createAccount(bvModalEvt) {
      bvModalEvt.preventDefault();
      const checkNumberOrName = await this.accountNameNumberCheck("create");
      if (!checkNumberOrName) {
        return;
      }
      this.$checkAccessRight("AccountCreation", "Save").then((res) => {
        if (res.data.valid) {
          this.$refs.createValidation.validate().then((success) => {
            if (success) {
              if (this.phoneNumberCheck) {
                this.AccountModalLoading = true;
                this.accountData.CreationDate = moment().format("YYYY-MM-DD");
                this.accountData.CreationTime = moment().format("HH:mm:ss");

                store
                  .dispatch("account/insertIntoCustomer", {
                    auth: this.user,
                    accountData: this.accountData,
                  })
                  .then((res) => {
                    if (res.data.info == "Pass") {
                      this.accountCreateMessage();
                      this.AccountModalLoading = false;
                      this.accountCreateModal = false;
                      this.resetAccountModal();
                      this.getAllEntries();
                    } else {
                      this.errorMessage(res.data.message);
                    }
                  })
                  .catch((error) => {
                    this.AccountModalLoading = false;
                    this.accountCreateModal = false;
                    this.getAllEntries();

                    console.log(error);
                  });
              } else {
                this.phoneNumberMessage(
                  this.$t(
                    "account_creation.messages.please_add_equal_phone_number"
                  )
                );
              }
            } else {
              let notEnteredDatas = "";

              let checkRequiredDatas = _.map(
                this.$refs.createValidation.errors,
                (value, key) => ({ key, value })
              );

              if (checkRequiredDatas.length > 0) {
                let first = true;
                checkRequiredDatas.forEach((r) => {
                  if (r.value.length > 0) {
                    if (first) {
                      notEnteredDatas += r.key;
                      first = false;
                    } else {
                      notEnteredDatas += "," + r.key;
                      first = false;
                    }
                  }
                });
                this.checkRequiredInputsMessage(notEnteredDatas, 20000);
              }
            }
          });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //****************CREATE END

    //****************SEARCH START
    searchAccountModal() {
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          this.accountData.HolderType = null;
          this.accountData.SettlementType = null;
          this.accountData.TaxResidency = "All";
          this.countryNames.unshift("All");
          this.accountSearchModal = true;
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    searchAccount(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          this.AccountModalLoading = true;
          this.loading = true;
          store
            .dispatch("account/selectFromCustomer", {
              auth: this.user,
              accountData: this.accountData,
            })
            .then((res) => {
              // console.log(res.data);
              this.allAccounts = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
              this.AccountModalLoading = false;
              this.resetAccountModal();
            })
            .catch((error) => {
              this.AccountModalLoading = false;
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //****************SEARCH END

    //****************VIEW START
    viewAccountModal(item) {
      this.$checkAccessRight("AccountCreation", "View").then((res) => {
        if (res.data.valid) {
          if (item.id != 0) {
            this.AccountModalLoading = true;
            this.loading = true;
            store
              .dispatch("account/selectAccNoFromCustomer", {
                auth: this.user,
                accountNumber: item.AccountNumber,
                ID: item.id,
              })
              .then((res) => {
                this.accountData = {
                  AccountName: res.data.AccountName,
                  AccountNumber: res.data.AccountNumber,
                  HolderType: res.data.HolderType,
                  SettlementType: res.data.SettlementType,
                  TaxResidency: res.data.TaxResidency,
                  ClientCommPerContract: res.data.ClientCommPerContract,
                  DisplayName: res.data.DisplayName,
                  Phone1: res.data.Phone1,
                  Phone2: res.data.Phone2,
                  ContactEmail: res.data.ContactEmail,
                  Email2: res.data.Email2,
                  Address: res.data.Address,
                  AmendedBy: res.data.AmendedBy,
                  AmendmentDate: res.data.AmendmentDate,
                  AmendmentReason: res.data.AmendmentReason,
                  AmendmentTime: res.data.AmendmentTime,
                  ClientNote: res.data.ClientNote,
                  CreatedBy: res.data.CreatedBy,
                  CreationDate: res.data.CreationDate,
                  CreationTime: res.data.CreationTime,
                  VerificationDate: res.data.VerificationDate,
                  VerificationTime: res.data.VerificationTime,
                  VerifiedBy: res.data.VerifiedBy,
                  History: res.data.History,
                  ClientId: res.data.ClientId,
                };

                this.loading = false;
                this.AccountModalLoading = false;
                this.accountViewModal = true;

                if (res.data.changedInputs) {
                  let setChangedInputs = _.map(
                    res.data.changedInputs,
                    (value, key) => ({ key, value })
                  );

                  if (setChangedInputs.length > 0) {
                    this.changedInputs = setChangedInputs;

                    setTimeout(() => {
                      if (this.changedInputs.length > 0) {
                        this.changedInputs.forEach((i) => {
                          if (this.accountData.hasOwnProperty(i.key)) {
                            let refKey = i.key;

                            if (this.$refs[refKey]) {
                              if (
                                this.$refs[refKey].$el.classList.length > 0 ||
                                this.$refs[refKey].$el.classList.indexOf(
                                  "v-select"
                                ) == 1
                              ) {
                                this.$refs[refKey].$el.classList.add(
                                  "change_selectbox_style"
                                ); // for v-select box
                              }

                              this.$refs[refKey].$el.style = "color:orange";
                            }
                          }
                        });
                      }
                    }, 500);
                  }
                }
              })
              .catch((error) => {
                this.AccountModalLoading = false;
                this.loading = false;
                console.log(error);
              });
          } else {
            this.AccountIDDoesntZeroMessage();
            this.loading = false;
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    viewAccount() {
      this.accountViewModal = false;
    },
    //****************VIEW END

    //****************createCodeSelectedAccount START
    createCodeSelectedAccount(item) {
      this.$checkAccessRight("AccountCreation", "Save").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (item.VerifiedBy != null) {
              if (item.AccountNumber != null) {
                this.createCodeModalLoading = true;
                this.loading = true;
                store
                  .dispatch("account/createCodeSelectedAccount", {
                    auth: this.user,
                    accountNumber: item.AccountNumber,
                    accountName: item.AccountName,
                  })
                  .then((res) => {
                    if (res.data.info == "Pass") {
                      this.createCodeAccountData = {
                        ID: item.id,
                        AccountName: item.AccountName,
                        AccountNumber: item.AccountNumber,
                        AccountCcy: res.data.customerInfo.HolderType,
                        AccountType: this.optAccountTypes[0],
                        AccountCode: res.data.customerInfo.AccountCode,
                        HolderType: "USD",
                        DisplayName: res.data.customerInfo.DisplayName,
                        Phone1: res.data.customerInfo.Phone1,
                        Phone2: res.data.customerInfo.Phone2,
                        ContactEmail: res.data.customerInfo.ContactEmail,
                        Email2: res.data.customerInfo.Email2,
                        Address: res.data.customerInfo.Address,
                        AmendedBy: res.data.customerInfo.AmendedBy,
                        AmendmentDate: res.data.customerInfo.AmendmentDate,
                        AmendmentReason: res.data.customerInfo.AmendmentReason,
                        AmendmentTime: res.data.customerInfo.AmendmentTime,
                        ClientNote: res.data.customerInfo.ClientNote,
                        CreatedBy: res.data.customerInfo.CreatedBy,
                        CreationDate: res.data.customerInfo.CreationDate,
                        CreationTime: res.data.customerInfo.CreationTime,
                        VerificationDate:
                          res.data.customerInfo.VerificationDate,
                        VerificationTime:
                          res.data.customerInfo.VerificationTime,
                        VerifiedBy: res.data.customerInfo.VerifiedBy,
                        History: res.data.customerInfo.History,
                      };

                      this.optAccountCcy = res.data.currencies;

                      this.createCodeAccountData.AccountCode =
                        this.createCodeAccountData.AccountType.slice(0, 4) +
                        item.AccountNumber;

                      this.loading = false;
                      this.createCodeModalLoading = false;
                      this.createCodeModal = true;
                    } else {
                      this.errorMessage(
                        this.$t("account_creation.messages.not_found_customer")
                      );
                      this.loading = false;
                    }
                  })
                  .catch((error) => {
                    this.createCodeModalLoading = false;
                    this.loading = false;
                    console.log(error);
                  });
              } else {
                this.warningMessage(
                  this.$t(
                    "account_creation.messages.entry_does_not_account_number"
                  )
                );
              }
            } else {
              this.warningMessage(
                this.$t("account_creation.messages.entry_does_not_verify_yet")
              );
            }
          } else {
            this.AccountIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //create code model OK
    createNewAccountCode(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("AccountCreation", "Save").then((res) => {
        if (res.data.valid) {
          this.$refs.createCodeValidation.validate().then((success) => {
            if (success) {
              this.createCodeModalLoading = true;
              store
                .dispatch("account/insertIntoCodeAccount", {
                  auth: this.user,
                  codeAccountData: this.createCodeAccountData,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.accountCodeCreateMessage();
                    this.createCodeModalLoading = false;
                    this.createCodeModal = false;
                    this.$router.push({
                      path: `/account-creation/selected-account-codes/${this.createCodeAccountData.ID}`,
                      query: {
                        accountName: this.createCodeAccountData.AccountName,
                      },
                    });
                    //this.getAllEntries();
                  } else {
                    if (res.data.info == "wrong_number") {
                      this.errorMessage(
                        this.$t(
                          "account_creation.messages.account_number_wrong_number"
                        )
                      );
                    } else if (res.data.info == "wrong_currency") {
                      this.errorMessage(
                        this.$t(
                          "account_creation.messages.account_number_wrong_currency"
                        )
                      );
                    } else if (res.data.info == "wrong_code") {
                      this.errorMessage(
                        this.$t(
                          "account_creation.messages.account_number_wrong_code"
                        )
                      );
                    } else {
                      this.errorMessage(res.data.message);
                    }
                    this.createCodeModalLoading = false;
                  }
                })
                .catch((error) => {
                  this.createCodeModalLoading = false;
                  //this.createCodeModal = false;
                  //this.getAllEntries();
                  console.log(error);
                });
            } else {
              let notEnteredDatas = "";

              let checkRequiredDatas = _.map(
                this.$refs.createCodeValidation.errors,
                (value, key) => ({ key, value })
              );

              if (checkRequiredDatas.length > 0) {
                let first = true;
                checkRequiredDatas.forEach((r) => {
                  if (r.value.length > 0) {
                    if (first) {
                      notEnteredDatas += r.key;
                      first = false;
                    } else {
                      notEnteredDatas += "," + r.key;
                      first = false;
                    }
                  }
                });
                this.checkRequiredInputsMessage(notEnteredDatas, 20000);
              }
            }
          });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //****************createCodeSelectedAccount END
    bringCodesSelectedAccount(item) {
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          if (item.id != 0) {
            if (item.VerifiedBy != null) {
              if (item.AccountNumber != null) {
                this.$router.push({
                  path: `/account-creation/selected-account-codes/${item.id}`,
                  query: { accountName: item.AccountName },
                });
              } else {
                this.warningMessage(
                  this.$t(
                    "account_creation.messages.entry_does_not_account_number"
                  )
                );
              }
            } else {
              this.warningMessage(
                this.$t("account_creation.messages.entry_does_not_verify_yet")
              );
            }
          } else {
            this.AccountIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    updateAccountStatus(item) {
      if (item.id != 0) {
        if (item.AccountNumber != null) {
          this.updateStatusList.push(item.AccountNumber);
          let Status = item.Status === "Active" ? "Archived" : "Active";
          store
            .dispatch("account/updateAccountStatus", {
              updateStatusList: this.updateStatusList,
              Status: Status,
            })
            .then((res) => {
              this.allAccounts = res.data.items;
              this.totalRows = res.data.items.length;
              this.updateStatusList = [];
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.warningMessage(
            this.$t("account_creation.messages.entry_does_not_account_number")
          );
        }
      } else {
        this.AccountIDDoesntZeroMessage();
      }
    },

    archiveMultipleAccounts(trades) {
      if (trades.length > 0) {
        this.updateStatusList = trades;
        let Status = "Archived";
        store
          .dispatch("account/updateAccountStatus", {
            updateStatusList: this.updateStatusList,
            Status: Status,
          })
          .then((res) => {
            this.allAccounts = res.data.items;
            this.totalRows = res.data.items.length;
            this.updateStatusList = [];

            this.showMultiplecheckboks = false;
            this.selectedMultipleTrades = [];
            this.multiSelectListStatusData = [];
            this.showMultiSelectProcessModal = false;
            this.multiSelectProcessModalTableData = [];

            this.loading = false;
          })
          .catch((error) => {
            this.databaseErrorMessage();
            console.log(error);
            this.loading = false;
          });
      } else {
        this.AccountIDDoesntZeroMessage();
      }
    },

    bringArchivedAccounts() {
      store
        .dispatch("account/bringArchivedAccounts")
        .then((res) => {
          this.onlyArchived = true;
          this.allAccounts = res.data.items;
          this.totalRows = res.data.items.length;
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
          this.loading = false;
        });
    },

    bringAllAccountCodes() {
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          this.$router.push({
            name: `allAccountCodes`,
          });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    resetAccountModal() {
      this.accountCreateModal = false;
      this.accountSearchModal = false;
      this.accountAmendModal = false;
      this.createCodeModal = false;
      this.accountVerifyModal = false;
      this.accountViewModal = false;
      this.changedInputs = [];

      this.accountData = {
        AccountName: null,
        AccountNumber: null,
        HolderType: "INDIVIDUAL",
        SettlementType: "CUSTODIAN",
        TaxResidency: "United Kingdom",
        ClientCommPerContract: null,
        DisplayName: null,
        Phone1: null,
        Phone2: null,
        ContactEmail: null,
        Email2: null,
        Address: null,
        ID: null,
        AmendedBy: null,
        AmendmentDate: null,
        AmendmentReason: null,
        AmendmentTime: null,
        ClientNote: null,
        CreatedBy: null,
        CreationDate: null,
        CreationTime: null,
        VerificationDate: null,
        VerificationTime: null,
        VerifiedBy: null,
      };

      this.createCodeAccountData = {
        AccountName: null,
        AccountNumber: null,
        AccountCcy: "CURRENT",
        AccountType: null,
        AccountCode: null,
        HolderType: null,
        DisplayName: null,
        Phone1: null,
        Phone2: null,
        ContactEmail: null,
        Email2: null,
        Address: null,
        ID: null,
        ClientNote: null,
      };

      this.preAccount = null;
      this.phoneNumberCheck = true;
      this.amendedReasonText = null;
      this.optAccountCcy = [];
      var index = this.countryNames.indexOf("All");
      if (index !== -1) {
        this.countryNames.splice(index, 1);
      }
      this.AccountModalLoading = false;
    },

    legendClass(item, type) {
      const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      const amendedRowColor = "table-amended";
      const expiredOrderRowColor = "table-expiredOrder";
      const reversedInitiateRowColor = "table-reverseInitiated";
      const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      const sampleColor = "sampleColor";

      if (!item || type !== "row") {
        return;
      }

      // if (item.VerifiedBy != null) {
      //   return verifiedRowColor;
      // }
      if (item.AmendedBy != null) {
        return amendedRowColor;
      }
    },

    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("account_creation.messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    amendOpearionNotDoneMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("account_creation.messages.amend_operation_not_done"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    verifyMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("account_creation.messages.entry_already_verify"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    accountCreateMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("account_creation.messages.account_create_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    accountCodeCreateMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "account_creation.messages.account_currency_create_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successVerifiedMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("account_creation.messages.account_verify_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successInsertAccountMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: message,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    wrongToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    phoneNumberMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    warningMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    AccountIDDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("account_creation.messages.account_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    verfiedAmendMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Info",
          text: this.$t("account_creation.messages.verified_account_amend"),
          icon: "InfoIcon",
          variant: "primary",
        },
      });
    },
    accountNumberNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("account_creation.messages.account_number_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("account_creation.messages.not_authorized"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },

    multiSelectAllProcesesSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("multiCheckBoxTitles.multiselect_processes_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    multiSelectAllProcesesWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("multiCheckBoxTitles.multiselect_processes_warning"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectCopyWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("multiCheckBoxTitles.multiselect_copy_warning"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllTradesCopySuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("multiCheckBoxTitles.multiselect_copy_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    warningToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    dateFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("account_creation.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    noChangesMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("account_creation.messages.you_not_change_account"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    clockFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("HH:mm:ss");
      } else {
        return "-";
      }
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes("to")) {
            this.filterDateStart = this.rangeDate.split(" to ")[0];
            this.filterDateEnd = this.rangeDate.split(" to ")[1];

            this.selectedEvents();
          }
        }
      },
    },
    selectedMultipleTrades: {
      handler: function(val, old) {
        if (val.length > 0) {
          this.multiSelectListStatusData = [];
          if (
            _.filter(val, {
              VerifiedBy: null,
              VerificationTime: null,
              VerificationDate: null,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Verify",
              title: this.$t("multiCheckBoxTitles.verify"),
              icon: "CheckSquareIcon",
              color: "green",
            });
          }
        } else {
          this.multiSelectListStatusData = [];
        }
      },
    },
  },
};
</script>

<style>
#accountModal .modal-header .modal-title {
  color: white !important;
}

.table-amended {
  background-color: #ffff99 !important;
}
</style>

<style scoped>
.padding_08 {
  padding: 0.7rem !important;
}
/* .table-verified {
  background-color: greenyellow !important;
} */

/* #table-container {
  display: flex;

  flex-wrap: nowrap;

  overflow-x: auto;
}

#table-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */
</style>

<style>
#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}
#actions-popover-legend {
  width: 300px !important;
  max-width: 300px !important;
}
.compact-textarea-lg {
  min-height: 40px !important;
}
.ID_background {
  background-color: gainsboro !important;
}
.column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-width: 150px !important;
}

.ID_column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.sticky-column {
  position: sticky;
  background-color: #fff;
  z-index: 1;
}
.sticky-column:nth-child(1) {
  left: 0;
}
.sticky-column:nth-child(2) {
  left: 50px;
}
.other-col {
  z-index: 0;
}

.b-table-sticky-column:last-child {
  right: 0;
}

.sticky-header {
  position: sticky;
  top: 78px;
  z-index: 999;
  /* Add other styling as needed */
}

.b-table-sticky-header {
  max-height: unset !important;
  height: inherit !important;
}
.hide-rows thead tr[role="row"] {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(77, 73, 73);
}

.full-names-list {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
}

.full-names-list li {
  padding: 10px; /* Add padding to each list item */
  margin-bottom: 5px; /* Add margin between list items */
  background-color: #f8f9fa; /* Optional: Add background color */
  border-radius: 5px; /* Optional: Add border radius */
}

.full-names-list li:hover {
  background-color: #e9ecef; /* Optional: Change background color on hover */
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/src/sass/vue-context";

.change_selectbox_style {
  .vs__dropdown-toggle {
    .vs__selected-options {
      .vs__selected {
        color: orange;
      }
    }
  }
}
</style>
<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

// .v-select::v-deep .vs__selected {

//     position: relative !important;

//   }

.popover-body .popover.show {
  width: 800px !important; /* Adjust the width as needed */
  max-width: 1000px !important; /* Adjust the width as needed */
}
.scrollable-popover {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: scroll;
}

.card::v-deep .card-body {
  padding: 0.8rem 0.7rem !important;

  .card-title {
    font-weight: 500 !important;
    font-size: 1.285rem !important;
    margin-bottom: 0.6rem !important;
    text-decoration: none !important;
  }
}

// trade controls buttons
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
